import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"
import { IndividualSurvey } from "../components/survey"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"

import Blockquote from "../components/blockquote"
import ImageGrid from "@components/ImageGrid"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  RequestAQuoteButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const customGlassGridItems = [
  {
    image: `/images/custom-glass/antique-mirror.jpg`,
    text: "Antique Mirror",
    subtext:
      "Our antique mirrors offer a consistent soft and subtle pattern that creates a unique and intimate feel to a room or space.",
    url: `/antique-mirror`,
  },
  {
    image: `/images/custom-glass/back-painted-glass.jpg`,
    text: "Back Painted Glass",
    subtext:
      "Ideal for high-traffic spaces, Back Painted glass is resistant to humidity and does not require grouting.",
    url: `/back-painted-glass`,
  },
  {
    image: `/images/custom-glass/laminated-glass.jpg`,
    text: "Laminated Glass",
    subtext:
      "Create unique designs using different types of decorative glass laminated together while adding the benefits of safety, UV protection and sound dampening.",
    url: `/laminated-glass`,
  },
  {
    image: `/images/custom-glass/pattern-glass.jpg`,
    text: "Pattern Glass",
    subtext:
      "Provides distinctive designs that add visual interest and different opacity levels to any space.",
    url: `/pattern-glass`,
  },
  {
    image: `/images/custom-glass/crackle-glass.jpg`,
    text: "Crackle Glass",
    subtext:
      "Crackle glass is not just any shattered glass. Its unique and exclusive design makes any piece of décor, such as tabletops and kitchen backsplashes, an extraordinary addition.",
    url: `/crackle-glass`,
  },
]

const CustomGlass = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout
      heroTitle="CUSTOM GLASS"
      heroBackgroundImage="/images/custom-glass/header-custom-decorative-glass.jpg"
    >
      <SEO title="Custom Decorative Glass | ArtVue Glass" ogDescription="ArtVue Glass is a trusted partner with designers, interior decorators, architects and glazing contractors to combine glass materials together to create the design look you desire without compromising function and durability." />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Everything We Do is Custom</h2>
            <p>
              ArtVue Glass in-house fabrication capabilities and years of
              experience enables them to know how to combine different glass
              materials together to create the design look you desire without
              compromising function and durability.
            </p>
            <p>
              The wide selection of products and options enables ArtVue Glass to
              work with diverse clientele of commercial businesses and
              individual homeowners throughout the world.
            </p>
            <p>
              ArtVue Glass is a trusted partner with designers, interior
              decorators, architects, glazing contractors and individual
              homeowners who wish to design unique glass creations to fit in
              unusual spaces.
            </p>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-glass/custom-glass.jpg" alt="Custom Glass" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Types of Custom Glass</h2>
            <p>
              The ArtVue Glass team offers tailored architectural decorative
              glass and mirror because of the vast array of materials with which
              we work. These include:
            </p>
            <ul>
              <li>
                Annealed, tempered, laminated, and laminated-tempered glass.
              </li>
              <li>
                Clear, low iron, patterned, tinted, and back painted glass.
              </li>
              <li>Standard and antique mirror glass.</li>
              <li>
                Specialized interlayers for both decorative and structural
                applications.
              </li>
              <li>
                Our product families also form the foundation of our custom
                glass.
              </li>
            </ul>
          </div>
        }
      />
      <div className="section textured">
      <ImageGrid
        content={customGlassGridItems}
        hideGrid
        listContent
        title="Custom Glass"
        itemsPerRow={3}
      />
      </div>
      <PageWrapper color="grey">
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <p>
              If you have a custom project in mind, contact the experts at
              ArtVue Glass and take the first step toward making your vision a
              reality. We are happy to offer a free consultation, answer all of
              your questions so you have confidence that we will deliver on your
              needs.
            </p>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Request A Quote",
                size: "tiny",
                icon: "file pdf outline",
              }}
            >
              <RequestQuote />
            </FormWrapper>
          </div>
        }
      />
      </PageWrapper>
    </PageLayout>
  )
}

export default CustomGlass
